<!-- Aside (Mobile Navigation) -->
<aside class="main-aside" [ngClass]="navmethod ? '' : 'open'">
    <a class="navbar-brand" routerLink="/"> <img src="assets/img/logo-bolle-niek.jpg" alt="logo"> </a>
    <div class="aside-scroll">
        <app-mobilemenu></app-mobilemenu>
    </div>
</aside>
<div class="aside-overlay aside-trigger" (click)="toggleNav()"></div>
<!-- Header Start -->
<header class="main-header header-1 header-absolute">
    <div class="container">
        <nav class="navbar">
            <!-- Logo -->
            <a class="navbar-brand" routerLink="/"> <img src="assets/img/logo-bolle-niek.jpg" alt="logo"> </a>
            <!-- Menu -->
            <app-menu></app-menu>
            <div class="header-controls">
                <!-- Toggler -->
                <div class="aside-toggler aside-trigger" (click)="toggleNav()">
                    <span></span>
                    <span></span>
                    <span></span>
<!--                    <img src="assets/img/burger.png" style="width: 30px;">-->
                </div>
            </div>
        </nav>
    </div>
</header>
<!-- Header End -->
